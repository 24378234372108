import { FC } from 'react';
import React, { useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import ErrorIcon from '@mui/icons-material/Error';
import CheckIcon from '@mui/icons-material/Check';
import SearchIcon from '@mui/icons-material/Search';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';
import { Avatar, Badge, Box, Button, Chip, ListItem, Stack, Tab, Tabs, Tooltip, useTheme } from '@mui/material';
import User from '../../../../Models/UserModels/User';
import { OverviewCardTitle } from '../../Home.styled';
import MobilePages from '../../../../Constants/MobilePages';
import Landing from '../../../../Models/LandingModels/Landing';
import WeightNoteTypes from '../../../../Constants/WeightNoteTypes';
import LandingStatuses from '../../../../Constants/LandingStatuses';
import WeightNote from '../../../../Models/WeightNoteModels/WeightNote';
import WeightNoteType from '../../../../Models/WeightNoteModels/WeightNoteType';
import { Ellipsis } from '../IncompleteLandingsTable/IncompleteLandingsTable.styled';
import { Card, CardData, CardLabel, ListItemCard, ListItemCardData } from './WeightNotesTable.styled';

interface WeightNotesTableProps {
  selectedLanding: Landing;
  selectedWeightNote: WeightNote;
  weightNoteTypes: WeightNoteType[];
  loading: boolean;
  user: User;
  weightNoteLoading: boolean;
  handleSelectWeightNote: (weightNote?: WeightNote, newWeightNoteId?: number, weightNotes?: WeightNote[]) => void;
  toggleNewWeightNoteModalOpen: () => void;
  currentPage: string;
  isMobileView: boolean;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

/**
 * Functional component for WeightNoteInfo.
 * Displays the information of the selected weightNote.
 * @param props 
 * - takes in various values used for dropDown values.
 * - takes in the selected landing and the selected weightNote.
 * - takes in a function to fetch landings.
 * @returns {JSX} - Responsible for returning the weightNote info.
 * should render the following components:
 * - EditWeightNoteModal
 */
const WeightNotesTable: FC<WeightNotesTableProps> = (props) => {

  const theme = useTheme();

  const WEIGHT_NOTES_TAB = 0;
  const INVALID_WEIGHT_NOTES_TAB = 1;
  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    setTabValue(WEIGHT_NOTES_TAB);
  }, [props.selectedLanding.id]);

  const getValidWeightNotes = () => {
    if (!props.selectedLanding.weightNotes) return [];
    if (props.selectedLanding.landingStatus.id === LandingStatuses.OPEN) {
      return props.selectedLanding.weightNotes.filter((weightNote) => {
        return (
          weightNote.isCredit === false &&
          weightNote.undoWeightNoteId === null &&
          weightNote.weightNoteType.id !== WeightNoteTypes.FINAL_REWEIGHTING &&
          !(
            weightNote.weightNoteType.id === WeightNoteTypes.PRODUCT_SAMPLE_ID &&
            weightNote.isClosed
          )
        );
      });
    }
    else {
      return props.selectedLanding.weightNotes.filter((weightNote) => {
        return (
          weightNote.isCredit === false &&
          weightNote.undoWeightNoteId === null &&
          !(weightNote.weightNoteType.id === WeightNoteTypes.FINAL_REWEIGHTING) &&
          !(weightNote.isClosed && weightNote.weightNoteType.id === WeightNoteTypes.TO_REWEIGHING) &&
          !(weightNote.isClosed && weightNote.weightNoteType.id === WeightNoteTypes.TO_HOME_REWEIGHING) &&
          !(weightNote.weightNoteType.id === WeightNoteTypes.PRODUCT_SAMPLE_ID && weightNote.isClosed)
        );
      });
    }
  };

  const getInvalidWeightNotes = () => {
    if (!props.selectedLanding.weightNotes) return [];
    if (props.selectedLanding.landingStatus.id === LandingStatuses.OPEN) {
      return props.selectedLanding.weightNotes.filter((weightNote) => {
        return (
          (weightNote.isCredit === true ||
            weightNote.undoWeightNoteId !== null ||
            (weightNote.isClosed && weightNote.weightNoteType.id === WeightNoteTypes.PRODUCT_SAMPLE_ID)
          ) && weightNote.weightNoteType.id !== WeightNoteTypes.FINAL_REWEIGHTING
        );
      });
    }
    else {
      return props.selectedLanding.weightNotes.filter((weightNote) => {
        return (
          (weightNote.isCredit === true ||
            weightNote.undoWeightNoteId !== null ||
            (weightNote.isClosed && weightNote.weightNoteType.id === WeightNoteTypes.PRODUCT_SAMPLE_ID) ||
            (weightNote.isClosed && weightNote.weightNoteType.id === WeightNoteTypes.TO_REWEIGHING) ||
            (weightNote.isClosed && weightNote.weightNoteType.id === WeightNoteTypes.TO_HOME_REWEIGHING)) &&
          weightNote.weightNoteType.id !== WeightNoteTypes.FINAL_REWEIGHTING
        );
      });
    }
  }

  const nullifycolumnHeaders = React.forwardRef(() => {
    return null;
  });

  function getStatusChipWeightNote(status: 'opin' | 'lokuð' | 'endurvigtun' | 'frávik') {
    if (status === 'lokuð') {
      return <Chip color="success" icon={<CheckIcon />} size='small' sx={{ "&.MuiChip-root .MuiChip-icon": { marginRight: "-12px" } }} />;
    }
    if (status === 'endurvigtun') {
      return <Chip color="error" icon={<ErrorIcon />} size='small' sx={{ "&.MuiChip-root .MuiChip-icon": { marginRight: "-12px" } }} />;
    }
    if (status === 'frávik') {
      return <Chip color="error" icon={<NewReleasesIcon />} size='small' sx={{ "&.MuiChip-root .MuiChip-icon": { marginRight: "-12px" } }} />;
    }
    return <Chip color="default" icon={<NewReleasesIcon />} size='small' sx={{ "&.MuiChip-root .MuiChip-icon": { marginRight: "-12px" } }} />;
  }

  const weightNoteColumns: GridColDef[] = [
    {
      field: 'recieverName', headerName: 'Viðtakandi', flex: 1, valueGetter: (params) => params.row?.receiver?.name, renderCell: (params) => (
        <ListItemCard>
          <ListItemCardData>
            <Ellipsis style={{ color: theme.palette.text.primary }}>{params.row?.receiver?.name}</Ellipsis>
            {params.row?.hasDeviation ? getStatusChipWeightNote('frávik') :
              getStatusChipWeightNote(params.row?.isClosed ? 'lokuð' :
                params.row?.weightNoteType.id === WeightNoteTypes.FROM_REWEIGHTER ? 'endurvigtun' :
                  'opin')}
          </ListItemCardData>
          <ListItemCardData>
            <Avatar sx={{ bgcolor: 'white', fontSize: 13, color: '#212121', border: '1px solid #cdcdcd', fontWeight: '500', height: 17, width: 80 }} variant="rounded">
              {params.row?.totalQuantity + ' kg'}
            </Avatar>
            <Ellipsis>
              <CardLabel style={{ color: theme.palette.mode === "dark" ? "white" : undefined }}>
                {params.row?.weightNoteType?.name}
              </CardLabel>
            </Ellipsis>
          </ListItemCardData>
        </ListItemCard>
      )
    }
  ];

  const sumShip: number | undefined = getValidWeightNotes()?.reduce((acc, row) => {
    return acc + row.totalQuantity;
  }, 0);
  const totalFromShip = new Intl.NumberFormat('de-DE', { style: 'decimal', maximumFractionDigits: 0, }).format(sumShip ?? 0);

  const WeightNoteFooter = () => {
    return (
      <span style={{ paddingTop: '0.4em', paddingBottom: '0.45em' }}>
        <ListItem>
          <CardData style={{ flex: 1, display: 'flex', justifyContent: 'space-between', color: theme.palette.text.primary }}>
            <b>Samtals</b>
            {totalFromShip} kg
          </CardData>
        </ListItem>
      </span>
    );
  };

  return (
    <>
      {((props.isMobileView && props.currentPage === MobilePages.WEIGHT_NOTE_SELECTION) || !props.isMobileView) &&
        <Card style={{
          height: props.isMobileView ? 'calc(100% - 13em)' : 'calc(100% - 2em)',
          width: props.isMobileView ? '100%' : '',
          margin: props.isMobileView ? '0' : '1em 0 1em 1em',
          backgroundColor: theme.palette.mode === "dark" ? "#272727" : undefined,
          borderRadius: props.isMobileView ? '0' : '',
          border: props.isMobileView ? 'none' : ''
        }}>
          <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flexWrap: 'wrap' }}>
            <OverviewCardTitle>
              Vigtarnótur
            </OverviewCardTitle>
            <Button
              aria-label="new-weightNote"
              size='medium'
              color='primary'
              variant='contained'
              onClick={props.toggleNewWeightNoteModalOpen}
              sx={{ margin: '0.75em' }}
              disabled={
                props.selectedLanding.landingStatus?.id === LandingStatuses.CLOSED ||
                props.selectedLanding.landingStatus?.id === LandingStatuses.NEEDS_REWEIGHTING ||
                props.user?.role?.id === Number(process.env.REACT_APP_ROLE_READ_ID) ||
                !props.selectedLanding?.id
              }
            >
              <AddIcon />
              <Tooltip title='F6' arrow>
                <span>Ný vigtarnóta</span>
              </Tooltip>
            </Button>
          </span>

          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabValue} onChange={handleTabChange} >
              <Tab icon={<Badge badgeContent={getValidWeightNotes()?.length} color="info"><ContentPasteIcon /></Badge>} label='GILDAR' style={{ width: "50%" }} {...a11yProps(WEIGHT_NOTES_TAB)} />
              <Tab icon={<Badge badgeContent={getInvalidWeightNotes()?.length} sx={{
                "& .MuiBadge-badge": {
                  color: "white",
                  backgroundColor: "grey"
                }
              }}><ContentPasteOffIcon /></Badge>} label='ÓGILDAR' style={{ width: "50%" }} {...a11yProps(INVALID_WEIGHT_NOTES_TAB)} />
            </Tabs>
          </Box>

          <DataGrid
            rows={tabValue === WEIGHT_NOTES_TAB ? getValidWeightNotes() : getInvalidWeightNotes()}
            columns={weightNoteColumns}
            loading={props.weightNoteLoading}
            hideFooterPagination
            hideFooterSelectedRowCount
            hideFooter
            getRowHeight={() => 'auto'}
            onRowClick={(row) => props.handleSelectWeightNote(undefined, Number(row.id))}
            rowSelectionModel={props.selectedWeightNote.id ? [props.selectedWeightNote.id] : []}
            columnVisibilityModel={{
              recieverSSN: false,
              iscredit: false,
              undoWeightNoteId: false
            }}
            disableColumnMenu
            sx={{
              "&.MuiDataGrid-root": {
                borderLeft: 0,
                borderRight: 0,
                borderTop: 0,
                borderRadius: 0,
              },
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
              "&.MuiDataGrid-root .MuiDataGrid-row:focus-within": {
                backgroundColor: theme.palette.mode === "dark" ? theme.palette.info.dark : "#d9ebfc"
              },
              "&.MuiDataGrid-root .MuiDataGrid-row.Mui-selected": {
                backgroundColor: theme.palette.mode === "dark" ? theme.palette.info.dark : "#d9ebfc"
              },
              "&.MuiDataGrid-virtualScroller": {
                overflow: 'auto'
              }
            }}
            slots={{
              columnHeaders: nullifycolumnHeaders,
              noRowsOverlay: () => (
                <Stack height="90%" alignItems="center" justifyContent="center">
                  <SearchIcon />
                  {props.selectedLanding.id ? tabValue === WEIGHT_NOTES_TAB ? 'Engar vigtarnótur' : 'Engar ógildar vigtarnótur' : 'Engin löndun valin'}
                </Stack>
              )
            }}
          />
          <WeightNoteFooter />
        </Card>
      }
    </>
  );
}

export default WeightNotesTable;
