import React, { FC } from 'react';
import { LandingTableWrapper, Card, CardData, CardLabel, Ellipsis } from './IncompleteLandingsTable.styled';
import Landing from '../../../../Models/LandingModels/Landing';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import SearchIcon from "@mui/icons-material/Search";
import { Avatar, Chip, Stack, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import LandingStatuses from '../../../../Constants/LandingStatuses';

interface IncompleteLandingsTableProps {
  landingData: Landing[];
  selectedLanding: Landing;
  handleSelectLanding: (landing: Landing, newLandingId?: number, landings?: Landing[]) => void;
  fetchCompletedLandings: (pageNumber: number) => void;
  fetchFilteredLandings: (pageNumber: number) => void;
  hasFilter: boolean;
  shouldHandleScroll: boolean;
  landingLoading: boolean;
}

/**
 * Functional component for IncompleteLandingsTable.
 * Displays the table of incomplete landings and weight notes.
 * As well as completed or filtering landings.
 * @param props 
 * - takes in an array of landing objects
 * - takes in the selected landing and selected weightNote
 * - takes in functions to handle fetching in the parent
 * - takes in various dropdown values.
 * - takes in a user object
 * @returns {JSX} - Responsible for returning the table of landings and weight notes.
 */

const IncompleteLandingsTable: FC<IncompleteLandingsTableProps> = (props: IncompleteLandingsTableProps) => {

  const [landingsExpanded, setLandingsExpanded] = React.useState<boolean>(true);
  const theme = useTheme();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleScroll = (e: any) => {
    if (e.target) {
      const threshold = 2; // Add a small threshold
      const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + threshold;

      if (bottom && props.shouldHandleScroll) {
        if (props.hasFilter) {
          props.fetchFilteredLandings(Math.round(props.landingData?.length / 10) + 1);
        } else {
          props.fetchCompletedLandings(Math.round(props.landingData?.length / 10) + 1);
        }
      }
    }
  };

  const toggleLandingsExpand = () => {
    setLandingsExpanded(!landingsExpanded);
  }

  function getStatusChipLanding(statusId: number | undefined) {
    switch (statusId) {
      case LandingStatuses.OPEN:
        return <Chip color="default" icon={<NewReleasesIcon />} size='small' sx={{ "&.MuiChip-root .MuiChip-icon": { marginRight: "-12px" } }} />;
      case LandingStatuses.CLOSED:
        return <Chip color="success" icon={<CheckIcon />} size='small' sx={{ "&.MuiChip-root .MuiChip-icon": { marginRight: "-12px" } }} />;
      case LandingStatuses.CORRECTED:
        return <Chip color="warning" icon={<WarningIcon />} size='small' sx={{ "&.MuiChip-root .MuiChip-icon": { marginRight: "-12px" } }} />;
      case LandingStatuses.NEEDS_REWEIGHTING:
        return <Chip color="error" icon={<ErrorIcon />} size='small' sx={{ "&.MuiChip-root .MuiChip-icon": { marginRight: "-12px" } }} />;
      default:
        return null;
    }
  }

  const landingColumns: GridColDef[] = [
    {
      field: 'ship', headerName: 'Heiti skips', flex: 1, valueGetter: (params) => params.row?.ship?.name, renderCell: (params) => (
        <Card>
          <CardData>
            <Ellipsis style={{ color: theme.palette.text.primary }}>{params.row?.ship?.name}</Ellipsis>
            {getStatusChipLanding(params.row?.landingStatus.id)}
          </CardData>
          <CardData>
            <Avatar sx={{ bgcolor: params.row?.id === props.selectedLanding.id ? theme.palette.mode === "dark" ? theme.palette.secondary.main : '#1976d2' : 'grey', fontSize: 13, fontWeight: '500', height: 17, width: 40 }} variant="rounded">
              {params.row?.ship?.shipRegistrationNumber}
            </Avatar>
            <CardLabel style={{ color: theme.palette.text.primary }}>{dayjs(new Date(params.api.getCellValue(params.rowNode.id, 'landingStarts'))).format('DD.MM.YYYY')}</CardLabel>
          </CardData>
        </Card>
      )
    }
  ];

  const nullifycolumnHeaders = React.forwardRef(() => {
    return null;
  });

  return (
    <React.Fragment>
      <LandingTableWrapper data-testid="landing-table-wrapper" onScroll={handleScroll}>
        <DataGrid
          autoHeight
          rows={props?.landingData ? props.landingData : []}
          columns={landingColumns}
          loading={props.landingLoading}
          hideFooterSelectedRowCount
          pageSizeOptions={[]}
          hideFooter={props.landingData?.length < 100}
          getRowHeight={() => 'auto'}
          onRowClick={(row) => { props.handleSelectLanding(props.selectedLanding, Number(row.id), props.landingData); toggleLandingsExpand(); }}
          rowSelectionModel={props.selectedLanding?.id ? [props.selectedLanding.id] : []}
          disableColumnMenu
          sx={{
            "&.MuiDataGrid-root": {
              minHeight: "100%",
              border: 0
            },
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            "&.MuiDataGrid-root .MuiDataGrid-row:focus-within": {
              backgroundColor: theme.palette.mode === "dark" ? "#383838" : "#f5f5f5"
            },
            "&.MuiDataGrid-root .MuiDataGrid-row.Mui-selected": {
              backgroundColor: theme.palette.mode === "dark" ? theme.palette.info.dark : "#d9ebfc"
            },
            "&.MuiDataGrid-root .MuiDataGrid-virtualScroller": {
              overflowX: 'hidden', overflowY: 'hidden'
            }
          }}
          slots={{
            columnHeaders: nullifycolumnHeaders,
            noRowsOverlay: () => (
              <Stack height="90%" alignItems="center" justifyContent="center">
                <SearchIcon />
                Engar landanir
              </Stack>
            )
          }}
        />
      </LandingTableWrapper>
    </React.Fragment>
  );
}

export default IncompleteLandingsTable;