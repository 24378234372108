import React, { FC, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Avatar, Badge, Divider, FormControlLabel, ListItemIcon, Switch, styled, useTheme } from '@mui/material';
import { OrganizationNameHeader, UserNameCard, OrganizationInfoCard, OrganizationNameCard, UserInfoCardContainer, UserInfoHeaderContainer, UserRole } from './Header.styled';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AuthService } from '../../Services/AuthService';
import User from '../../Models/UserModels/User';
import HarbourModal from './components/HarbourModal/HarbourModal';
import EmailModal from './components/EmailModal/EmailModal';
import VehicleModal from './components/VehicleModal/VehicleModal';
import Vehicle from '../../Models/CatchRegistrationModels/Vehicle';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import LogoutIcon from '@mui/icons-material/Logout';
import DescriptionIcon from '@mui/icons-material/Description';
import ReportsModal from './components/ReportsModal/ReportsModal';
import Landing from '../../Models/LandingModels/Landing';
import Gear from '../../Models/WeightNoteModels/Gear';
import FishingStock from '../../Models/CatchRegistrationModels/FishingStock';
import UserHarbours from '../../Models/UserModels/UserHarbours';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import DeviationModal from './components/DeviationModal/DeviationModal';

const authService = new AuthService();

interface HeaderProps {
  user?: User | null;
  refetchLandings?: () => void;
  isReWeighing?: boolean;
  selectedPage?: 'Endurvigtun' | 'Löndunarkerfi' | 'Skýrslur';
  isAuthorized: boolean;
  fishingGears?: Gear[];
  fishingStock?: FishingStock[];
  vehicles?: Vehicle[];
  harbours?: UserHarbours[];
  refetchVehicles?: () => void;
  refetchHarbours?: () => void;
  setSelectedLanding?: (landing: Landing, newLandingId?: number, landings?: Landing[], newWeightNoteId?: number) => void;
  showSnackbar?: (message: string, severity: string) => void;
  darkMode?: boolean;
  setDarkMode?: (darkMode: boolean) => void;
}

/** 
 * Functional component for Header.
 * Displays the header of the application, with user info and dropdown for settings, change of harbour and logout.
 * Should also display the name of the current harbour as well as pages in the application.
 * @param {HeaderProps} props
 * - takes in a user object
 * - takes in a function to be called when the header changes
 * - takes in a boolean to determine if the user is in the reweighing application
 * - takes in a string to determine which page is selected
 * - takes in a boolean to determine if the user is authorized
 * @returns {JSX} - Responsible for returning the header.
 * 
 * Should render the following components:
 * - ChangeHarbourModal
*/

const Header: FC<HeaderProps> = (props) => {

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [openHarbour, setOpenHarbour] = React.useState<boolean>(false);
  const [openEmails, setOpenEmails] = React.useState<boolean>(false);
  const [openDeviations, setOpenDeviations] = React.useState<boolean>(false);
  const [openVehicleModal, setOpenVehicleModal] = React.useState<boolean>(false);
  const [openReportsModal, setOpenReportsModal] = React.useState<boolean>(false);
  const theme = useTheme();
  const ADMIN_ROLE_ID = 18;
  const CORRECTION_ROLE_ID = 19;
  const F4_KEY_CODE = 115;

  const pages = [];
  const settings = [];
  const pageToHrefMap: { [key: string]: string } = {
    Löndunarkerfi: '/landings',
    Endurvigtun: '/reweighting',
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const keyboardOpenHarbourSelection = (e: any) => {
    const allModalsClosed = document.body.getElementsByClassName("MuiDialog-root")?.length === 0;
    if ((e.keyCode) === F4_KEY_CODE) {
      e.preventDefault();
      if (allModalsClosed) {
        setOpenHarbour(true);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', keyboardOpenHarbourSelection);
    return () => {
      document.removeEventListener('keydown', keyboardOpenHarbourSelection);
    };
  }, []);

  if (!props.isReWeighing) {
    if (!props.isAuthorized) {
      settings.push({ name: 'Skrá út', icon: <LogoutIcon />, textColor: 'error' });
    }
    else {
      settings.push(
        { name: 'Skýrslur', icon: <DescriptionIcon />, textColor: 'default', tooltip: '' },
        { name: 'Höfn', icon: <AccountBalanceIcon />, textColor: 'default', tooltip: 'F4' },
        { name: 'Netföng', icon: <AlternateEmailIcon />, textColor: 'default', tooltip: '' },
        { name: 'Ökutæki', icon: <LocalShippingIcon />, textColor: 'default', tooltip: '' })
      if (props.user?.role.id === ADMIN_ROLE_ID || props.user?.role.id === CORRECTION_ROLE_ID) {
        settings.push({ name: 'Frávik', icon: <PriorityHighIcon color='warning' />, textColor: '#f57c00', tooltip: '' });
      }
      settings.push({ name: 'Skrá út', icon: <LogoutIcon color='error' />, textColor: 'error', tooltip: '' });
      pages.push('Löndunarkerfi');
    }
  }
  else {
    settings.push({ name: 'Skrá út', icon: <LogoutIcon />, textColor: 'error' });
    pages.push('Endurvigtun');
  }

  const handleHarbourClose = () => {
    setOpenHarbour(false);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleSettingsClick = (setting: string) => {
    switch (setting) {
      case 'Skýrslur':
        setOpenReportsModal(true);
        break;
      case 'Höfn':
        setOpenHarbour(true);
        break;
      case 'Netföng':
        setOpenEmails(true);
        break;
      case 'Ökutæki':
        setOpenVehicleModal(true);
        break;
      case 'Frávik':
        setOpenDeviations(true);
        break;
      case 'Skrá út':
        authService.logout();
        break;
    }
    handleCloseUserMenu();
  };

  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 16,
    height: 16,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.common.white : '#3a3b3c',
  }));

  const shouldRenderUserInfoHeader = window.innerWidth >= 800;

  const DarkModeSwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
      margin: 1,
      padding: 0,
      transform: 'translateX(6px)',
      '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(22px)',
        '& .MuiSwitch-thumb:before': {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
            '#fff',
          )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
        },
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
      width: 32,
      height: 32,
      '&::before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
      },
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      borderRadius: 20 / 2,
    },
  }));

  return (
    <AppBar position="static" sx={{ backgroundColor: theme.palette.mode === "dark" ? theme.palette.info.dark : theme.palette.primary.main }}>
      {!props.isReWeighing && (
        <>
          {props.showSnackbar && props.user && (
            <>
              <EmailModal
                open={openEmails}
                toggleOpen={() => setOpenEmails(!openEmails)}
                user={props.user}
                showSnackbar={props.showSnackbar}
              />
              {props.refetchVehicles && (
                <VehicleModal
                  open={openVehicleModal}
                  toggleOpen={() => setOpenVehicleModal(!openVehicleModal)}
                  refetchVehicles={props.refetchVehicles}
                  vehicles={props.vehicles ? props.vehicles : []}
                  user={props.user}
                  showSnackbar={props.showSnackbar}
                />
              )}
              {props.setSelectedLanding && (
                <>
                  <DeviationModal
                    open={openDeviations}
                    toggleOpen={() => setOpenDeviations(!openDeviations)}
                    user={props.user}
                    showSnackbar={props.showSnackbar}
                    setSelectedLanding={props.setSelectedLanding}
                  />
                  <ReportsModal
                    open={openReportsModal}
                    toggleOpen={() => setOpenReportsModal(!openReportsModal)}
                    user={props.user}
                    userHarbours={props.harbours || []}
                    setSelectedLanding={props.setSelectedLanding}
                    refetchLandings={props.refetchLandings}
                    fishingGears={props.fishingGears ? props.fishingGears : []}
                    fishingStock={props.fishingStock ? props.fishingStock : []}
                  />
                </>
              )}
              {props.refetchHarbours && props.refetchLandings && (
                <HarbourModal
                  user={props.user}
                  showSnackbar={props.showSnackbar}
                  open={openHarbour}
                  handleClose={handleHarbourClose}
                  harbours={props.harbours ? props.harbours : []}
                  refetchHarbours={props.refetchHarbours}
                  refetchLandings={props.refetchLandings}
                />
              )}
            </>
          )}
        </>
      )}
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', bgcolor: 'primary' }} variant='dense'>

        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <img style={{ height: '2.5em', width: '2.5em' }} alt="logo" src="https://images.ctfassets.net/8k0h54kbe6bj/3W68zG8kVqVY7DU1qHxVEA/4d403503b05488cc8385578a92ef399e/fiskist-merki_2x.png"></img>
          {pages.map((page) => (
            <Typography
              key={page}
              component="a"
              variant={props.selectedPage === page ? 'h5' : 'body1'}
              href={pageToHrefMap[page] || '/'}
              sx={{
                padding: '.5em',
                fontWeight: props.selectedPage === page ? 600 : 'normal',
                color: 'inherit',
                textDecoration: 'none'
              }}
            >
              {page}
            </Typography>
          ))}
        </Box>

        <Box>
          <Tooltip title="">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              {shouldRenderUserInfoHeader && (
                <UserInfoHeaderContainer>
                  <OrganizationNameHeader>{props.user?.isReweighingUser ? props.user.actor.name : props.user?.name}</OrganizationNameHeader>
                  <OrganizationNameHeader>{props.user?.isReweighingUser ? props.user.name : props.user?.userPickedHarbourName}</OrganizationNameHeader>
                </UserInfoHeaderContainer>)}


              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={
                  <SmallAvatar>
                    <ExpandMoreIcon fontSize='inherit' />
                  </SmallAvatar>
                }
              >
                <Avatar sx={{ height: '2em', width: '2em', bgcolor: '#b3baba', fontSize: '0.65em', border: '2px solid #b3baba' }}>{props.user?.name ? props.user?.name[0] : 'N'}</Avatar>
              </Badge>
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <UserInfoCardContainer>
              <Avatar sx={{ left: 'calc(50% - 2em)', height: '4em', width: '4em', bgcolor: '#b3baba' }}>{props.user?.name ? props.user?.name[0] : 'N'}</Avatar>
              <OrganizationNameCard>{props.user?.isReweighingUser ? props.user.actor.name : props.user?.name}</OrganizationNameCard>
              <UserNameCard>{props.user?.isReweighingUser ? props.user.name : props.user?.userPickedHarbourName}</UserNameCard>
              {!props.user?.isReweighingUser &&
                <UserRole>
                  {props.user?.role.name}
                </UserRole>}
              {props.user?.homeAddress || props.user?.zipArea ? (
                <OrganizationInfoCard>
                  <p> {props.user?.homeAddress ? props.user.homeAddress + "," : ''} {props.user?.zipArea} </p>
                </OrganizationInfoCard>
              ) : null}
              <Divider />
            </UserInfoCardContainer>
            <FormControlLabel
              control={
                <DarkModeSwitch
                  sx={{ ml: 2 }}
                  checked={props.darkMode}
                  onChange={() => props.setDarkMode && props.setDarkMode(!props.darkMode)}
                />}
              label={props.darkMode ? "Dökk stilling" : "Ljós stilling"}
            />
            {settings.map((setting) => (
              <Tooltip key={setting.name} title={setting.tooltip} placement="left">
                <MenuItem key={setting.name} onClick={() => handleSettingsClick(setting.name)}>
                  <ListItemIcon>
                    {setting.icon}
                  </ListItemIcon>
                  <Typography textAlign="center" color={setting.textColor}>{setting.name}</Typography>
                </MenuItem>
              </Tooltip>
            ))}
          </Menu>
        </Box>
      </Toolbar>

    </AppBar>
  );
}

export default Header;