import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthService } from '../../Services/AuthService';
import { getUser } from '../../Services/LondunarkerfiAPIService';
import { Box, CircularProgress } from '@mui/material';
import { HeaderWrapper, LoaderWrapper, RouteWithAuthWrapper } from './RouteWithAuth.styled';
import Header from '../Header/Header';
import User from '../../Models/UserModels/User';

interface RouteWithAuthProps {
  component: React.ComponentType<{ user: User, darkMode: boolean, setDarkMode: (darkMode: boolean) => void }>;
  reWeighting: boolean;
  darkMode: boolean;
  setDarkMode: (darkMode: boolean) => void;
}

export default function RouteWithAuth({ component: Component, reWeighting, darkMode, setDarkMode }: RouteWithAuthProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const location = useLocation();

  useEffect(() => {
    checkAuthorization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function checkAuthorization() {
    const authService = new AuthService();
    const oicdUser = await authService.getUser();

    if (!oicdUser) {
      setIsLoading(false);
      setIsAuthenticated(false);
      return;
    }
    const userData = await getUser(true);

    if (!userData || (reWeighting && !userData.isReweighingUser) || (!reWeighting && userData.isReweighingUser)) {
      /* returns unauthorized if:
      * no response from API.
      * prop was set to reweighting and a user not specified for reWeighting tries to access.
      * props was not set for reweighting and a user specified for reweighting tries to access. */
      setIsLoading(false);
      setIsAuthenticated(false);
      return;
    }
    setUser(userData);
    setIsLoading(false);
    setIsAuthenticated(true);
  }

  if (isLoading) {
    return (
      <RouteWithAuthWrapper>
        <HeaderWrapper>
          <Header isAuthorized={true} />
        </HeaderWrapper>
        <LoaderWrapper>
          <Box sx={{ display: 'flex' }}>
            <CircularProgress />
          </Box>
        </LoaderWrapper>
      </RouteWithAuthWrapper>
    );
  }

  if (!isAuthenticated || !user) {
    return <Navigate to="/unauthorized" state={{ from: location }} replace />;
  }

  return (
    <RouteWithAuthWrapper>
      <Component user={user} darkMode={darkMode} setDarkMode={setDarkMode} />
    </RouteWithAuthWrapper>
  );
}