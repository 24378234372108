import React, { useState, useEffect, FC, Fragment } from 'react';
import { getLandings } from '../../../../../Services/LondunarkerfiAPIService';
import Landing from '../../../../../Models/LandingModels/Landing';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import User from '../../../../../Models/UserModels/User';
import dayjs, { Dayjs } from 'dayjs';
import { Box, InputAdornment, TextField, useMediaQuery, useTheme } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import HarbourAutoComplete from '../../../../AutoComplete/HarbourAutoComplete';
import UserHarbours from '../../../../../Models/UserModels/UserHarbours';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { logError } from '../../../../../Helpers/LogError';

interface LandingReportsProps {
  user: User;
  userHarbours: UserHarbours[];
  setSelectedLanding: (landing: Landing) => void;
  refetchLandings?: () => void;
  toggleOpen: () => void;
}

const LandingReport: FC<LandingReportsProps> = (props: LandingReportsProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [landings, setLandings] = useState<Landing[]>([]);
  const [search, setSearch] = useState<string>('');
  const [landingStarts, setLandingStarts] = useState<Dayjs | null>(null);
  const [selectedHarbourFilter, setSelectedHarbourFilter] = useState<UserHarbours | null>(props.userHarbours.find((harbour) => harbour.id === props.user.userPickedHarbour) || null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const SCROLL_TOLERANCE = 5;

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      fetchLandings(1);
    }, 500);

    return () => clearTimeout(debounceTimer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, selectedHarbourFilter?.id, props.user?.userPickedHarbour]);

  useEffect(() => {
    fetchLandings(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landingStarts]);

  const fetchLandings = async (page: number) => {
    try {
      setLoading(true);
      const response = await getLandings(selectedHarbourFilter?.id, true, page, search, landingStarts?.isValid() ? landingStarts?.format('DD.MM.YYYY') : undefined);
      setLandings((prevLandings) => (page === 1 ? response : [...prevLandings, ...response]));

      setLoading(false);
    } catch (error) {
      setLoading(false);
      logError(error);
    }
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (e.currentTarget) {
      const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;
      const bottom = Math.abs(scrollHeight - scrollTop - clientHeight) <= SCROLL_TOLERANCE;
      if (bottom && !loading) {
        fetchLandings(Math.round(landings?.length / 10) + 1);
      }
    }
  };

  const handleSearchChange = (newSearch: string) => {
    setSearch(newSearch);
  };

  const handleLandingSelection = (landing: Landing) => {
    props.setSelectedLanding(landing);
    props.toggleOpen();
  }

  const columns: GridColDef[] = [
    { field: 'ship', headerName: 'Skip (nr.)', flex: 1, valueGetter: (params) => params.row.ship?.name + " (" + params.row.ship?.shipRegistrationNumber + ")", resizable: false },
    { field: 'landingStarts', headerName: 'Dagsetning', flex: 1, valueGetter: (params) => dayjs(params.row.landingStarts).format('DD.MM.YYYY'), resizable: false },
    { field: 'harbour', headerName: 'Höfn', flex: 1, valueGetter: (params) => params.row.harbour?.harbourName, resizable: false },
    { field: 'id', headerName: 'ID', flex: 1, resizable: false },
    { field: 'landingStatus', headerName: 'Staða', type: 'boolean', flex: 1, resizable: false },
  ];

  const handleHarbourChange = async (harbour: UserHarbours | null) => {
    setSelectedHarbourFilter(harbour);

    // TODO: Decide if we should switch harbour when harbour is changed in query select list.
    //if (harbour) {
    //const changeUserHarbourBody: ChangeHarbourBody = {
    //changeToHarbourID: harbour.id,
    //};
    //props.user.userPickedHarbour = harbour.id;
    //props.user.userPickedHarbourName = harbour.harbourName;

    //await changeUserHarbour(changeUserHarbourBody);
    //if (props.refetchLandings) {
    //  props.refetchLandings();
    //}
    //}
  };

  const handleDateChange = (newValue: Dayjs | null) => {
    const minDate = dayjs('15.05.2006', 'DD.MM.YYYY');
    const maxDate = dayjs();
    if (newValue && newValue.isValid() && !newValue.isBefore(minDate) && !newValue.isAfter(maxDate)) {
      setLandingStarts(newValue);
    }
  };

  return (
    <Fragment>
      <div style={{ width: '100%', display: 'flex', flexDirection: isMobile || isTablet ? 'column' : 'row', justifyContent: 'space-between', gap: '1em' }}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='is'>
          <DatePicker
            sx={{ width: '100%' }}
            disabled={loading}
            label='Löndun hefst'
            value={landingStarts}
            minDate={dayjs('15.05.2006', 'DD.MM.YYYY') as Dayjs}
            maxDate={dayjs()}
            onChange={handleDateChange}
            format="DD.MM.YYYY"
            slotProps={{
              field: {
                clearable: true, onClear: () => setLandingStarts(null)
              },
              actionBar: {
                actions: ['clear'],
              },
            }} />
        </LocalizationProvider>

        <HarbourAutoComplete
          onHarbourChange={handleHarbourChange}
          selectedHarbour={selectedHarbourFilter || undefined}
          harbours={props.userHarbours}
          sx={{ width: '100%' }}
          user={props.user}
        />

      </div>
      <TextField
        id="search-landing"
        label="Leita..."
        type="search"
        variant="standard"
        value={search}
        onChange={(e) => handleSearchChange(e.target.value)}
        autoFocus
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <Box sx={{ overflowY: 'auto' }} onScroll={handleScroll}>
        <DataGrid
          rows={landings}
          columns={columns}
          loading={loading}
          autoHeight
          onRowClick={(row) => { handleLandingSelection(row.row) }}
        />
      </Box>
    </Fragment>
  );
};

export default LandingReport;
